.modal-first-co {
  .ant-col {
    width: 50%;
    div:has(> #cgu_help) { 
      margin-bottom: 20px;
    }
  }

  .link-button {
    color: #1890ff;
  }
}
