.form-container {
    padding: 24px;
    height: 70vh;
    overflow: auto;
}

.ant-form-item-label {
    width: 100%;
}

.hide-input[disabled] {
    display: none;
}
