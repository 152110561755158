.header-container {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.header-end-container {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.header-start-container {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

.layout {
    background-color: #0d1f4b;
}

.custom-layout-header {
    background-color: #F6F7F8;
    height: 48px;
    padding: 0;
    z-index: 2;
    margin: 8px 0 8px 0 !important;
}

.user-name {
    margin-right: 10px;
}
