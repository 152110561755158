.filter_header {
    color: #0480FC;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 450;
}

.bl_filter_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    min-width: 150px;
    max-width: 150px;
    margin: 10px;
}

.header_wrapper {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    min-height: 32px;
}

.align-self-center {
    align-self: center;
}

.bottom-margin-col {
    margin-bottom: 8px !important;
}

.global-row > td {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.row-bl-filter {
    margin-top: 30px !important;
    margin-bottom: 5px !important;
}
