.information-wrapper {
    display: flex;
}

.side-section {
    width: 50%;
}

.title-infos {
    font-weight: bold;
    width: 70%;
}

.ant-drawer-content {
    background-color: #F5F5F5 !important;
}

.drawer-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.header-span {
    color: black;
    font-size: 18px;
}

.info-span {
    color: black;
    font-size: 14px;
    margin-bottom: 5px;
}

.m-bottom-20 {
    margin-bottom: 20px;
}

.close-button {
    position: absolute;
    top: 20px;
    left: 20px;
}