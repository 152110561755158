.bl_information {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.shipping-icon {
    width: 32px;
}

.btn-all-expand {
    margin: 10px;
}
