.container {
    width: 100%;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.audit-icon svg {
    fill: #0480FC;
}

.audit-icon-btn {
    border-color: #0480FC;
}

.audit-icon-no-pod svg {
    fill: grey;
}

.selected-row-on-return-fadeout td {
    background-color: rgba(4, 128, 252, 1); /* Starting background color */
    animation: fadeOut 6s forwards;
}

.selected-row-on-return-fadeout:hover td {
    background-color: rgba(4, 128, 252, 1); /* Starting background color */
}

.selected-row-on-return-fadeout .ant-table-cell-fix-right {
    background-color: #FFF !important;
}

@keyframes fadeOut {
    from {
        background-color: rgba(4, 128, 252, 1); /* Starting background color */
    }
    to {
        background-color: rgba(4, 128, 252, 0); /* Ending background color (fully transparent) */
    }
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.hidden-col {
    display: none;
}
