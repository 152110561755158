.modal-pod {
  display: flex;
  margin: auto;
  justify-content: space-between;
  justify-items: center;
  #modal_pod_view_button {
    margin-right: 20px;
  }
  #modal_pod_upload_button {

  }
}
