.bl-number {
    color: #3FABFF;
    font-size: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 450;
}

.bloc-header {
    color: black;
    font-size : 20px;
    font-weight: 450;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    color:black;
}
