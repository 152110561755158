.dropdown_wrapper {
    /*display: flex;
    justify-content: flex-start;
    align-items: flex-start;*/
    /*width: auto;*/
    margin: 10px 10px 10px 0 !important;
}

.filter_wrapper {
    width: auto;
    max-width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px;
}


.date_picker_wrapper {
    margin: 10px;
    width: auto;
    max-width: 230px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.switch_wrapper {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.input-date-wrapper {
    /*width: 200px;*/
    /*margin: 10px;*/
}

.remitter-select .ant-select-selector {
    background-color: #0480FC !important;
}

.remitter-select .ant-select-selection-placeholder {
    color: #fff !important;
}

.remitter-select .ant-select-arrow {
    color: #fff;
}

.remitter-select .ant-select-selection-item {
    color: #fff !important;
}
