#components-layout-demo-side .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

td.ant-table-column-sort {
    background-color: transparent !important;
}

.ant-table-column-sorters {
    justify-content: center !important;
}

.ant-table-tbody {
    cursor: pointer;
}

.table-row-red td {
    background-color: #FF6F6F !important;
}

.table-row-red .ant-table-cell-fix-right {
    background-color: #FFF !important;
}

.table-row-yellow {
    background-color: #FFF8EA !important;
}

.ant-table-tbody > tr.table-row-yellow:hover > td {
    background: none !important;
}
