.edition-row-container {
  display: flex;
  width: 100%;
  /*align-items: center;*/
  justify-content: center;
}

.edition-row-container:hover {
  .icon-edition {
    visibility: visible;
  }
}

.icon-edition {
  margin-left: 5px;
  visibility: hidden;
  :hover {
    cursor: pointer;
  }
}

.editing-row-container {
  display: flex;
  width: 100%;
  .ant-form {
    width: 100%;
  }
}



.icon-editing-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .cancel-button {
    margin-left: 5px;
  }
}