.col-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.row-flux > td {
    vertical-align: top;
    padding-top: 15px !important;
}

.maj-status-div {
    display: flex;
    justify-content: center;
    width: 125px;
}
