@media screen and (max-width: 600px) {
    .column {
        flex: 100% !important;
    }
}

.filter_header {
    color: #0480FC;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 450;
}

.export-column {
    width: auto;
    display: flex;
    align-items: flex-end;
    margin: 17px 10px 10px 10px;
    height: 80%;
}

.import-csv {
    margin-right: 10px;
}

.import-ged {
    margin-right: 10px;
}

.row-filter-col {
    margin-bottom: 20px;
}

.col-marg {
    margin-bottom: 10px !important;
}

.margin-space {
    margin-bottom: 10px !important;
}

.flex-column {
    display: flex !important;
    flex-direction: column !important;
}

.flex-end-justify {
    display: flex !important;
    justify-content: flex-end !important;
}

.flex-end-align-justify {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important
}

.input-delivery-num {
    width: 250px !important;
}

.space-add-cde {
    margin-bottom: 5px;
}
