.info-frame {
    border: 2px solid #94CFFF;
    background-color: #E6F6FF;
    border-radius: 4px;
    padding: 10px 25px 0 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.info-container {
    width: 50%;
    margin-bottom: 10px;
}