.shipping-button {
    border-radius: 25px;
}

.align-items {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.align-items .ant-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
